export const New = () => {
  return (
    <div className="new">
      <p className="new__title">Quoi de neuf ?</p>
      <p className="new__description">
        La section bonus et promotions a été mise à jour.
      </p>
      <p className="new__description">
        L'emplacement de la succursale, du distributeur automatique ou du
        terminal le plus proche est ajouté à la carte.
      </p>
      <p className="new__description">
        La prise en charge de Face ID et Touch ID a été ajoutée.
      </p>
      <p className="new__description">
        Correction d'un problème avec les notifications push rencontré par
        certains utilisateurs d'Android 10.
      </p>
      <p className="new__description">
        Correction d'un problème où le texte dans les paramètres était trop
        petit pour les utilisateurs d'Android.
      </p>
    </div>
  );
};
